import { createFocusTrap } from "focus-trap";
import { AccessibilityHelpers } from './Util/Accessibility.js';

let accessibilityHelpersInstance = new AccessibilityHelpers();

class Header {
  constructor(mobileBP = 991) {
    this.headerEl = document.getElementById('Header');
    if (this.headerEl) {
      this.menuOverlayEl = this.headerEl.querySelector('.Header-overlay');
      this.navEl = this.headerEl.querySelector('.Header-content');
      this.menuEl = this.headerEl.querySelector('.Header-menu');
      this.menuBtnEl = this.headerEl.querySelector('.Header-toggleIcon');
      this.menuItems = this.headerEl.querySelectorAll('.Header-menuItem a');
      this.localePickerEl = this.headerEl.querySelector('.Header-localePicker');
      this.localeBtnEl = this.headerEl.querySelector('.Header-localePickerBtn');

      this.hasBeenSetupMobile = false;
      this.mobileBP = window.matchMedia(`(max-width: ${mobileBP}px)`);
      this.wasDesktop = !this.mobileBP.matches;
      this.focusTrap = createFocusTrap('#Header', {
        onActivate: () => {
          this.handleHeaderTabIndexes(0);
        },
        onDeactivate: () => {
          this.handleHeaderTabIndexes(-1);
        },
      });

      this.localeBtnEl.addEventListener('click', () => {
        this.localePickerEl.classList.toggle('is-open');
      });
      this.handleMobileHeader();
      this.mobileBP.addListener(this.handleMobileHeader.bind(this));
    }
  }

  handleMobileHeader() {
    if (this.mobileBP.matches) {
      this.enableMobileHeader();
    } else {
      this.resetMobileHeader();
    }
  }

  enableMobileHeader() {
    const headerLogic = () => {
      this.menuOverlayEl.style['display'] = 'block';

      if (this.headerEl.classList.toggle('is-open')) {
        this.menuOverlayEl.style['opacity'] = '1';
        this.focusTrap.activate();
      } else {
        this.menuOverlayEl.style['opacity'] = '0';
        window.setTimeout(() => {
          this.menuOverlayEl.style['display'] = 'none';
        }, 300); // matches opacity transition time
        this.focusTrap.deactivate();
      }

      accessibilityHelpersInstance.toggleAriaState(this.navEl, 'hidden');
      accessibilityHelpersInstance.toggleAriaState(this.menuBtnEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'hidden');
    };

    this.handleHeaderTabIndexes(-1);
    accessibilityHelpersInstance.setAriaProp(this.navEl, 'hidden', true);

    if (!this.hasBeenSetupMobile) {
      this.menuBtnEl.addEventListener('click', headerLogic);
      this.menuOverlayEl.addEventListener('click', headerLogic);
      this.hasBeenSetupMobile = true;
    }
    this.wasDesktop = false;
  }

  resetMobileHeader() {
    if (!this.wasDesktop) {
      this.headerEl.classList.remove('is-open');
      this.navEl.style = '';
      accessibilityHelpersInstance.setAriaProp(this.navEl, 'hidden', false);
      this.focusTrap.deactivate();
      this.handleHeaderTabIndexes(0);
      this.wasDesktop = true;
    }
  }

  handleHeaderTabIndexes(tabIndex) {
    accessibilityHelpersInstance.setTabIndex(this.menuItems, tabIndex);
    accessibilityHelpersInstance.setTabIndex(this.menuOverlayEl, tabIndex);
  }
}

export {
  Header
}
