class Footer {
  constructor() {
    this.localePickerEl = document.querySelector('.Footer-localePicker');
    this.localeBtnEl = document.querySelector('.Footer-localePickerBtn');
    if (this.localePickerEl && this.localeBtnEl) {
      this.localeBtnEl.addEventListener('click', () => {
        this.localePickerEl.classList.toggle('is-open');
      });
    }
  }
}

export {
  Footer
}
